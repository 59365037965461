import React from 'react';
import profilPix from "../assets/img/FCHAvatar2.jpg"

const Pp = () => {
    return (
        <div className="profil-pic">
            <img src={profilPix} alt="développeur web front end react"/>
        </div>
    );
};

export default Pp;