import React from 'react';

const Navigation = () => {
    return (

        <nav className="navigation">
            <span><a href="#section1">Accueil</a></span>
            <span><a href="#section2">Projets</a></span>
            <span><a href="#section4">Tarifs</a></span>
            <span><a href="#section3">Contact</a></span>

        </nav>

    );
};

export default Navigation;