import React from 'react';

const ContactButton = () => {
    return (
        <a href="#section3" style={{textDecoration:"none"}}><div className="contact-button">
            Contact
        </div></a>
    );
};

export default ContactButton;